import React from 'react'

import Faq from 'src/components/Faq/index'
import { grayscale } from 'src/styles/colors'

import { Section } from './style'
import pageContext from './../../pageContext.json'

export const FaqSection = () => {
  return (
    <Section id='perguntas-frequentes'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-sora fw-600 text-grayscale--500 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 mb-4'>
              Dúvidas frequentes sobre recarga
            </h2>
          </div>
          <Faq
            id='recarga'
            className=''
            answerData={pageContext.structuredData.faq}
            searchBg={grayscale[100]}
            answersColor='text-grayscale--500'
            columns={{ lg: 1, xl: 1 }}
          />
        </div>
      </div>
    </Section>
  )
}
