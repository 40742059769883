import React from 'react'

import PaymentNotification from '@interco/icons/build-v4/orangeds/MD/payment-notification'
import AgendaIcon from '@interco/icons/build-v4/orangeds/MD/agenda'
import GiftIcon from '@interco/icons/build-v4/orangeds/MD/gift'
import TagIcon from '@interco/icons/build-v4/orangeds/MD/tag'
import { orange } from 'src/styles/colors'

export const advantagesList = [
    {
        title: 'Formas de pagamento',
        description: 'Pague com seu saldo em conta ou cartão de crédito Inter, sem taxa e com pontos no Inter Loop.',
        icon: <PaymentNotification width='20' height='20' color={orange.extra} />,
    },
    {
        title: 'Recarga Programada',
        description: 'Agende a frequência da sua <strong>recarga automática</strong> e não fique mais sem <strong>crédito no celular</strong>!',
        icon: <AgendaIcon width='20' height='20' color={orange.extra} />,
    },
    {
        title: 'Promoções exclusivas',
        description: 'Aproveite as melhores ofertas da sua operadora e gigas extras de internet!',
        icon: <GiftIcon width='20' height='20' color={orange.extra} />,
    },
    {
        title: 'Serviço gratuito',
        description: 'Não pague nada além do valor da recarga de celular e faça com segurança e praticidade.',
        icon: <TagIcon width='20' height='20' color={orange.extra} />,
    },
]
