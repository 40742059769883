
export const posts = [
 {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/recarga-blog1/image.webp',
    title: 'Recarga de celular no Super App',
    cta: 'https://blog.inter.co/como-colocar-credito-no-celular/',
    date: '01/11/2022',
 },
 {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/blog2-recarga/image.webp',
    title: 'Como saber meu número de celular',
    cta: 'https://blog.inter.co/como-saber-meu-numero/',
    date: '25/11/2022',
 },
 {
    image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/blog3-recarga/image.webp',
    title: 'Como rastrear um celular perdido ou roubado',
    cta: 'https://blog.inter.co/como-rastrear-um-celular/',
    date: '19/11/2020',
 },
]
