import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { SectionWithModal } from '../../styles'

export const Section = styled(SectionWithModal)`
  min-height: 540px;
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-360-recarga-0/image.webp') no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    min-height: 420px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-768-recarga-0/image.webp') no-repeat;
    background-size: cover;
  }

  @media ${device.desktopLG} {
    min-height: 435px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-1024-recarga-0/image.webp') no-repeat;
    background-size: cover;
  }

  @media ${device.desktopXL} {
    min-height: 605px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/hero-1440-recarga-0/image.webp') no-repeat;
    background-size: cover;
  }
`
