export const howToList = [
  {
    title: 'Como consultar saldo da Claro',
    description:
       '<ul><li>Ligue para *1052#, escolha a opção 2 e depois a opção 1.</li> <li>Ou use o aplicativo "Minha Claro", disponível para iOS e Android.</li> <li>Você pode também enviar um SMS para 1052 e escolher a opção 4.</li> <li>Ou acesse o site Minha Claro</li></ul>',
  },
  {
    title: 'Como consultar saldo da Tim',
    description:
      '<ul><li>Ligue para *222# e o saldo aparecerá na tela.</li> <li>Ou envie um SMS para o número 222.</li> <li>Você também pode ligar para *222 e selecionar a opção 3.</li> <li>Use o App Meu Tim no Android ou iOS.</li> <li>Acesse o site Meu Tim, faça o login e o saldo aparecerá logo em seguida.</li></ul>',
  },
  {
    title: 'Como consultar saldo da Vivo',
    description:
      '<ul><li>Ligue para *8000 e selecione a opção "Saldo".</li> <li>Ou envie um SMS para 8000 com a palavra SALDO.</li> <li>Você também pode ligar para *5005 e ouvir seu saldo.</li> <li>Acesse o site Meu Vivo, faça o login e seu saldo aparecerá na tela.</li> <li>Ou baixe o app Meu Vivo Móvel, disponível para Android e iOS.</li></ul>',
  },
]
