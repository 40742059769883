import React, { createContext, useContext, useState } from 'react'

import { ViewsEnum } from '../components/_ModalContentFactory'

export type ModalContentDispatchT = {
  modalContentView: ViewsEnum | undefined;
  setModalContentView: React.Dispatch<React.SetStateAction<ViewsEnum | undefined>>;
}

type ModalContentProviderProps = {
  children: React.ReactNode;
}

const ModalContentContext = createContext<ModalContentDispatchT | undefined>(undefined)

const ModalContentProvider = ({ children }: ModalContentProviderProps) => {
  const [ modalContentView, setModalContentView ] = useState<ViewsEnum | undefined>()

  const value = {
    modalContentView,
    setModalContentView,
  }

  return (
    <ModalContentContext.Provider value={value}>
      { children }
    </ModalContentContext.Provider>
  )
}

export const useModalContent = () => {
  const context = useContext(ModalContentContext)
  if (context === undefined) {
    throw new Error('useModalContent must be used within a ModalContentProvider')
  }
  return context
}

export default ModalContentProvider
