import React from 'react'

import { ArticleData, ArticleDate, ArticleTitle, Content, Section } from './style'
import { posts } from './_posts'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ImageWebp from 'src/components/ImageWebp'

export const Blog = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    312, 216, 296, 360,
  ]

  const arrayHeight: number[] = [
    212, 147, 201, 245,
  ]

  const handleLink = (url: string, title: string) => {
    sendDatalayerEvent({
      section: 'dobra_9',
      section_name: 'Dicas sobre celular pra simplificar seu dia a dia',
      element_action: 'click banner',
      element_name: title,
      redirect_url: url,
    })
    window.open(url, '_blank')
  }

  return (
    <Section>
      <div className='container'>
        <div className='row mb-4'>
          <div className='col-12'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-0 mx-n1 text-center'>
              Dicas pra simplificar seu dia a dia
            </h2>
          </div>
        </div>

        <div className='row pt-3'>
          {posts.map((item: typeof posts[0]) => (
            <div className='col-12 col-md-4' key={item.title}>
              <Content className='mb-4' onClick={() => handleLink(item.cta, item.title)}>
                <ImageWebp
                  pathSrc={item.image}
                  altDescription='Tela do Super App do Inter para preencher os dados e colocar crédito no celular.'
                  arrayNumbers={arrayNumbers}
                  arrayNumbersHeight={arrayHeight}
                  className='d-block w-100'
                />
                <ArticleData>
                  <ArticleTitle className='font-sora fw-600 fs-20 lh-25 fs-lg-18 lh-lg-23 fs-xl-20 lh-xl-25 mb-2 mr-2'>{item.title}</ArticleTitle>
                  <ArticleDate className='font-sora fw-700 fs-12 lh-15 mb-0'>{item.date}</ArticleDate>
                </ArticleData>
              </Content>
            </div>
          ))}
        </div>

        <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 text-center mt-lg-3'>
          A <strong>recarga online</strong> é um serviço oferecido pelo Super App Inter pra você <strong>colocar crédito no celular</strong> utilizando seu próprio smartphone. E além de fazer sua recarga de celular, no Super App você também pode fazer <strong>recarga de TV pré-pago</strong> e <strong>recarga de bilhete único.</strong>
        </p>
        <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-grayscale--400 text-center'>
          É seguro, rápido e simples. Aproveita!
        </p>
      </div>
    </Section>
  )
}
