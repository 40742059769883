import React, { useState } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { ModalContentDispatchT, useModalContent } from '../context/_ModalContentContext'
import qrCodePrimaryAction from '../assets/qr-code-primary-action.jpg'
import qrCodeSecondaryAction from '../assets/qr-code-secondary-action.jpg'
import qrCodeTvAction from '../assets/qr-code-tv-action.jpg'
import qrCodeTransportAction from '../assets/qr-code-transport-action.jpg'

export enum ViewsEnum {
  primaryFlow = 'primaryFlow',
  heroSectionSecondaryFlow = 'heroSectionSecondaryFlow',
  tvSectionFlow = 'tvSectionFlow',
  transportSectionFlow = 'transportSectionFlow',
  accessToRecharge = 'accessToRecharge',
  downloadTheApp = 'downloadTheApp',
}

type SelectionViewProps = {
  primaryBtnClickListener?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  secondaryBtnClickListener?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  sectionName: string;
}

const SelectionView = ({ primaryBtnClickListener, secondaryBtnClickListener, sectionName }: SelectionViewProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const dataLayerPrimaryBtn: IDataLayerParams = {
    section: '',
    section_name: 'Recarregue agora direto pelo Super App',
    element_action: 'click button',
    element_name: 'Tenho conta no Inter',
  }

  const dataLayerSecondaryBtn: IDataLayerParams = {
    section: '',
    section_name: 'Recarregue agora direto pelo Super App',
    element_action: 'click button',
    element_name: 'Não tenho conta',
  }
  const onClickHandler = (dataLayer: IDataLayerParams) => {
    sendDatalayerEvent({
      section: 'm_' + sectionName,
      section_name: dataLayer.section_name,
      element_action: 'click button',
      element_name: dataLayer.element_name,
    })
  }
  return (
    <>
      <h3 className='fs-24 lh-30 text-grayscale--500 mb-4 fw-600 font-sora'>
        Recarregue agora direto pelo Super App
      </h3>

      <div className='d-flex mt-3'>
        <button
          className='btn btn--lg btn-orange--extra text-none w-100 mw-100'
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            primaryBtnClickListener?.(e)
            onClickHandler(dataLayerPrimaryBtn)
          }}
        >
          Tenho conta no Inter
        </button>
        <button
          className='btn btn--lg btn--outline btn--orange text-none w-100 mw-100 ml-3'
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            secondaryBtnClickListener?.(e)
            onClickHandler(dataLayerSecondaryBtn)
          }}
        >
          Não tenho conta
        </button>
      </div>
    </>
  )
}

const AccessToRecharge = ({ qrCodeSrc }: {qrCodeSrc: string}) => {
  return (
    <div className='d-flex flex-column align-items-center px-4'>
      <h3 className='fs-24 lh-30 text-grayscale--500 fw-600 font-sora mb-0'>
        Acesse o QR Code para fazer sua <span className='text-orange--extra'>recarga</span>
      </h3>
      <hr className='w-100' />
      <p className='font-sora fw-600 fs-16 lh-20 text-grayscale--400 mb-4'>
        Aponte a câmera do celular para o QR Code abaixo e finalize sua recarga diretamente pelo Super App Inter.
      </p>
      <img src={qrCodeSrc} className='d-block mt-3' width={230} height={230} />
    </div>
  )
}

const DownloadTheApp = ({ qrCodeSrc }: {qrCodeSrc: string}) => (
  <div className='d-flex flex-column align-items-center px-4'>
    <h3 className='fs-24 lh-30 text-grayscale--500 fw-600 font-sora mb-0'>
      Baixe o <span className='text-orange--extra'>Super App</span> e faça sua recarga
    </h3>
    <hr className='w-100' />
    <p className='font-sora fw-600 fs-16 lh-20 text-grayscale--400 mb-4'>
      O serviço de recarga pelo Super App é exclusivo para clientes Inter.
      <br />
      <br />
      Acesse o QR Code para baixar o app e abrir sua conta gratuita:
    </p>
    <img src={qrCodeSrc} className='d-block mt-3' width={230} height={230} />
  </div>
)

export const ModalContentFactory = ({ sectionName }: {sectionName: string}) => {
  const { modalContentView: view, setModalContentView }: ModalContentDispatchT = useModalContent()
  const [ selectedQrCode, setSelectedQrCode ] = useState('')
  if (!view) return null

  const viewOptions = {
    [ViewsEnum.primaryFlow]:
      (
        <SelectionView
          sectionName={sectionName}
          primaryBtnClickListener={() => { setSelectedQrCode(qrCodePrimaryAction); setModalContentView(ViewsEnum.accessToRecharge) }}
          secondaryBtnClickListener={() => { setSelectedQrCode(qrCodePrimaryAction); setModalContentView(ViewsEnum.downloadTheApp) }}
        />
      ),
    [ViewsEnum.heroSectionSecondaryFlow]:
      (
        <SelectionView
          sectionName={sectionName}
          primaryBtnClickListener={() => { setSelectedQrCode(qrCodeSecondaryAction); setModalContentView(ViewsEnum.accessToRecharge) }}
          secondaryBtnClickListener={() => { setSelectedQrCode(qrCodeSecondaryAction); setModalContentView(ViewsEnum.downloadTheApp) }}
        />
      ),
    [ViewsEnum.tvSectionFlow]:
      (
        <SelectionView
          sectionName={sectionName}
          primaryBtnClickListener={() => { setSelectedQrCode(qrCodeTvAction); setModalContentView(ViewsEnum.accessToRecharge) }}
          secondaryBtnClickListener={() => { setSelectedQrCode(qrCodeTvAction); setModalContentView(ViewsEnum.downloadTheApp) }}
        />
      ),
    [ViewsEnum.transportSectionFlow]:
      (
        <SelectionView
          sectionName={sectionName}
          primaryBtnClickListener={() => { setSelectedQrCode(qrCodeTransportAction); setModalContentView(ViewsEnum.accessToRecharge) }}
          secondaryBtnClickListener={() => { setSelectedQrCode(qrCodeTransportAction); setModalContentView(ViewsEnum.downloadTheApp) }}
        />
      ),
    [ViewsEnum.accessToRecharge]: (<AccessToRecharge qrCodeSrc={selectedQrCode} />),
    [ViewsEnum.downloadTheApp]: (<DownloadTheApp qrCodeSrc={selectedQrCode} />),
  }[view]

  return (
    <div>
      {viewOptions}
    </div>
  )
}
