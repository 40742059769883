import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  padding-top: 348.45px;
  padding-bottom: 40px;
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-360-recarga/image.webp') ${white} no-repeat top;
  background-size: 100% 348.45px;

  @media ${device.tablet} {
    display: flex;
    align-items: center;
    min-height: 545px;
    padding-top: 0;
    padding-bottom: 0;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-768-recarga/image.webp') ${white} no-repeat left;
    background-position-x: -115px;
  }

  @media ${device.desktopLG} {
    min-height: 617px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-1440-1024-recarga/image.webp') ${white} no-repeat left;
    background-position-x: -155px;
  }

  @media ${device.desktopXL} {
    min-height: 637px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra7-1440-1024-recarga/image.webp') ${white} no-repeat left;
  }

  img {
    width: 78px;
    height: 17px;

    @media ${device.desktopLG} {
      width: 95px;
      height: 21px;
    }
    @media ${device.desktopXL} {
      width: 146px;
      height: 32px;
    }
  }
`
