
export const operatorsList = [
    {
      image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/claro-recarga/image.webp',
      title: 'Recarga Claro',
      cta: '',
      alt: 'Marca da Claro',
    },
    {
      image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/tim-recarga/image.webp',
      title: 'Recarga Tim',
      cta: '',
      alt: 'Marca da Tim',
    },
    {
      image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/vivo-recarga/image.webp',
      title: 'Recarga Vivo',
      cta: '',
      alt: 'Marca da Vivo',
    },
    {
      image: 'https://central-imagens.bancointer.com.br/images-without-small-versions/operadoras-recarga/image.webp',
      title: 'Outras operadoras',
      cta: '',
      alt: 'Símbolo de telefone',
    },
  ]
