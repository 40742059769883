import React from 'react'

import { Section } from './style'
import { Acordion } from './components/_Acordion'
import { howToList } from './_howToList'

export const Saldo = () => {
  return (
    <Section id='perguntas-frequentes'>
      <div className='container'>
        <div className='row mb-3 mb-md-4'>
          <div className='col-12'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-5 text-md-center'>
              Consulte seu saldo e depois faça sua recarga online!
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--400 mb-0 mr-n3 mr-lg-0 text-md-center'>
              Não sabe quanto tem de crédito no celular? A gente te ajuda! Veja como consultar seu saldo na operadora:
            </p>
          </div>
        </div>

        <div className='row pt-md-3'>
          {howToList.map((item: typeof howToList[0]) => (
            <div className='col-12 col-md-4' key={item.title}>
              <Acordion title={item.title} description={item.description} />
            </div>

          ))}
        </div>
      </div>
    </Section>
  )
}
