import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;

    button {
      height: 48px;
    }
  }

  ul {
    padding-top: 0 !important;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  border: 1px solid ${grayscale[300]};
  border-radius: 8px; 
  width: 138px;
  height: 235px;

  @media ${device.tablet} {
    width: 180px;
    height: 230px;
  }
  @media ${device.desktopLG} {
    width: 240px;
    height: 294px;
    padding: 24px;
  }
`
