import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  img,
  svg {
    width: 100%;
  }
`

export const ArticleData = styled.article`
  background: ${white};
  margin-top: -20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding: 16px;
`

export const ArticleTitle = styled.h3`
  color: ${grayscale[500]};
  text-decoration: underline;
`

export const ArticleDate = styled.p`
  color: ${grayscale[300]};
`
