import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import {
  Hero,
  Bonus,
  Vantagens,
  RecargaCelular,
  Credito,
  Saldo,
  Cashback,
  RecargaTv,
  TransportRecharge,
  Economize,
  Blog,
  FaqSection,
} from './sections/_index'
import ModalContentProvider from './context/_ModalContentContext'
import SideModalProvider from './context/_SideModalContext'
import { ModalContentFactory } from './components/_ModalContentFactory'

const Recarga = () => {
  return (
    <Layout pageContext={pageContext}>
      <ModalContentProvider>
        <SideModalProvider width={600} portalSelector='#hero_section_recharge' ModalContent={<ModalContentFactory sectionName='dobra_01' />}>
          <Hero sectionId='hero_section_recharge' />
        </SideModalProvider>

        <SideModalProvider width={600} portalSelector='#bonus_on_recharge' ModalContent={<ModalContentFactory sectionName='dobra_02' />}>
          <Bonus sectionId='bonus_on_recharge' />
        </SideModalProvider>

        <SideModalProvider width={600} portalSelector='#online_recharge_benefits' ModalContent={<ModalContentFactory sectionName='dobra_03' />}>
          <Vantagens sectionId='online_recharge_benefits' />
        </SideModalProvider>

        <SideModalProvider width={600} portalSelector='#phone_recharge' ModalContent={<ModalContentFactory sectionName='dobra_04' />}>
          <RecargaCelular sectionId='phone_recharge' />
        </SideModalProvider>
        <SideModalProvider width={600} portalSelector='#how_to_recharge' ModalContent={<ModalContentFactory sectionName='dobra_05' />}>
          <Credito sectionId='how_to_recharge' />
        </SideModalProvider>
      </ModalContentProvider>
      <Saldo />
      <Cashback />
      <ModalContentProvider>
        <SideModalProvider width={600} portalSelector='#tv_recharge' ModalContent={<ModalContentFactory sectionName='dobra_08' />}>
          <RecargaTv sectionId='tv_recharge' />
        </SideModalProvider>

        <SideModalProvider width={600} portalSelector='#transport_recharge' ModalContent={<ModalContentFactory sectionName='dobra_09' />}>
          <TransportRecharge sectionId='transport_recharge' />
        </SideModalProvider>
      </ModalContentProvider>
      <Economize />
      <Blog />
      <FaqSection />
    </Layout>
  )
}

export default Recarga
