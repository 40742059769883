import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import { SectionWithModal } from '../../styles'

export const Section = styled(SectionWithModal)`
  padding-top: 40px;
  padding-bottom: 40px;

  @media ${device.desktopLG} {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: ${white};
  border: 1px solid ${grayscale[100]};
  box-shadow: 0px 4px 40px rgba(182, 172, 164, 0.25);
  border-radius: 16px;
  padding: 16px;
  height: 240px;
  margin-top: 16px;

  @media ${device.desktopLG} {
    height: 278px;
    margin-top: 8px;
  }

  @media ${device.desktopXL} {
    height: 305px;
    padding: 24px;
  }

  img {
    display: block;
    margin-bottom: 16px;
    width: 100%;
    height: 80px;

    @media ${device.desktopLG} {
      height: 136px;
      margin-bottom: 24px;
    }
  }

  button {
    height: 48px;
  }
`
