import React, { useState } from 'react'
import ArrowDownIcon from '@interco/icons/build-v4/orangeds/MD/chevron-down'

import { orange } from 'src/styles/colors'

import { AccordionContent, TransitionArrow } from '../style'

export const Acordion = ({ title, description }: {title: string; description: string}) => {
    const [ open, setOpen ] = useState(false)

    return (
      <AccordionContent open={open}>
        <div className='d-flex align-items-center justify-content-between' onClick={() => setOpen(!open)} role='button'>
          <h4 className='font-sora fw-700 fs-14 lh-17 fs-lg-16 lh-lg-20 fs-xl-20 lh-xl-25 text-grayscale--500 mb-0'>{title}</h4>
          <TransitionArrow open={open}>
            <ArrowDownIcon width={26} height={26} color={orange.extra} />
          </TransitionArrow>
        </div>
        {open && <div className='mt-3' dangerouslySetInnerHTML={{ __html: description }} />}
      </AccordionContent>
    )
}
