import React, { createContext, useContext, useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import { SideModal } from '@interco/inter-ui/components/Modal/SideModal'

export type SideModalDispatchT = {setIsModalOpen: (showModal: boolean) => void}
type SideModalProviderProps = {
  children: React.ReactNode;
  ModalContent: React.ReactNode;
  portalSelector?: string;
  width?: number | string;
}

const SideModalContext = createContext<SideModalDispatchT | undefined>(undefined)

const SideModalProvider = ({ children, ModalContent, portalSelector, width }: SideModalProviderProps) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false)

  const domReady = useDomReady()

  const value = {
    setIsModalOpen: (showModal: boolean) => setIsModalOpen(showModal),
  }

  const showModal = (domReady && (
    <SideModal
      visible={isModalOpen}
      onClickOutside={() => setIsModalOpen(false)}
      direction='right'
      bottomFade={false}
      portalSelector={portalSelector}
      onClose={() => setIsModalOpen(false)}
      width={width}
    >
      {ModalContent}
    </SideModal>
  ))
  return (
    <SideModalContext.Provider value={value}>
      {showModal}
      {children}
    </SideModalContext.Provider>
  )
}

export const useSideModal = () => {
  const context = useContext(SideModalContext)
  if (context === undefined) {
    throw new Error('useSideModal must be used within a SideModalProvider')
  }
  return context
}

export default SideModalProvider
