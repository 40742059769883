import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import ImageWebp from 'src/components/ImageWebp'
import { ModalContentDispatchT, useModalContent } from '../../context/_ModalContentContext'
import { SideModalDispatchT, useSideModal } from '../../context/_SideModalContext'
import { ViewsEnum } from '../../components/_ModalContentFactory'

import { Section } from './style'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

type RecargaCelularProps = {
  sectionId: string;
}

export const RecargaTv = ({ sectionId }: RecargaCelularProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const { setModalContentView }: ModalContentDispatchT = useModalContent()
  const { setIsModalOpen }: SideModalDispatchT = useSideModal()
  const width = useWidth()
  const titleH2 = 'Recarga de TV pré-paga'
  const deepLink = 'https://intergo.app/400ed49c'

  const arrayNumbers: number[] = [
    312, 336, 376, 456, 520,
  ]

  const arrayHeight: number[] = [
    336.75, 362.64, 405.83, 492, 561,
  ]

  const dataLayer: IDataLayerParams = {
    section: 'dobra_8',
    section_name: titleH2,
    element_action: 'click button',
    element_name: 'Recarregar TV',
  }

  const handleClick = () => {
    sendDatalayerEvent(dataLayer)
    setIsModalOpen(true)
    setModalContentView(ViewsEnum.tvSectionFlow)
  }

  return (
    <Section className='py-5 bg-orange' id={sectionId}>
      <div className='container'>
        <div className='row align-items-center justify-content-md-between'>
          <div className='col-12 text-center col-md-6 col-lg-5 mb-3 order-md-last'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/operadoras-recarga-prod/image.webp'
              altDescription='Mão segurando um controle remoto apontando pra TV pré-paga'
              arrayNumbers={arrayNumbers}
              arrayNumbersHeight={arrayHeight}
              className='float-md-right'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 mb-4 mb-md-0'>
            <h2 className='font-sora fw-600 fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 mb-4 mb-md-3 mr-md-n3'>
              {titleH2}
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mr-n3 mr-md-0 mr-lg-n3 mb-4 mb-xl-5'>
              Pelo Super App Inter você também faz <strong>recarga Sky pré-pago ou Claro TV</strong> e ganha cashback pra aproveitar um mundo de canais com muita economia.
            </p>
            {
              width < WIDTH_MD
              ? (
                <a
                  href={deepLink}
                  className='btn btn--lg btn-orange--extra text-white text-none w-100 mw-100 mt-2'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_08',
                      element_action: 'click button',
                      section_name: titleH2,
                      element_name: 'Recarregar TV',
                      redirect_url: deepLink,
                     })
                  }}
                >
                  Recarregar TV
                </a>
              )
              : (
                <button
                  className='btn btn--lg btn-orange--extra text-white text-none w-100 mw-100'
                  onClick={handleClick}
                >
                  Recarregar TV
                </button>
              )
            }
          </div>
        </div>
      </div>
    </Section>
  )
}
