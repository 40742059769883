import styled from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { orange, white } from 'src/styles/colors'
import { SectionWithModal } from '../../styles'

export const Section = styled(SectionWithModal)`
  min-height: 870px;
  background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-768-recarga/image.webp') no-repeat;
  background-size: contain;
  position: relative;

  @media ${device.tablet} {
    min-height: auto;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-768-recarga-bg/image.webp') ${white};
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
  }

  @media ${device.desktopLG} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-1440-recarga/image.webp') ${white};
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
  }

  @media ${device.desktopXL} {
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/dobra5-1024-recarga/image.webp') ${white};
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: -15px;
  }

  button {
    height: 48px;
  }

  .open-video {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media ${device.tablet} {
      top: 50%;
      left: 18%;
      transform: translate(-50%, -50%);
    }

    @media ${device.desktopLG} {
      top: 50%;
      left: 22%;
      transform: translate(-50%, -50%);
    }

    @media ${device.desktopXXXL} {
      top: 50%;
      left: 15%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 60px;
      height: 60px;

      @media ${device.desktopXL} {
        width: 80px;
        height: 80px;
      }
    }
  }
`

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  background: ${orange.extra};
  color: ${white};
  font-size: 16px;
  line-height: 20px;
  margin-right: 16px;

  @media ${device.desktopLG} {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    font-size: 20px;
    line-height: 25px;
  }

  @media ${device.desktopXL} {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
`
