import styled, { css } from 'styled-components'

import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const Section = styled.section`
  padding-top: 40px;
  padding-bottom: 16px;

  @media ${device.tablet} {
    padding-bottom: 40px;
  }
  @media ${device.desktopLG} {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  @media ${device.desktopXL} {
    padding-top: 96px;
    padding-bottom: 96px;
  }
`

export const AccordionContent = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border: 1px solid ${grayscale[100]};
  border-radius: 8px;
  margin-bottom: 24px;

  @media ${device.tablet} {
    margin-bottom: 0;

    ${({ open }: { open: boolean }) =>
      open &&
      css`
        height: 347px;
      `}

    p {
      max-width: 134px;
    }
  }

  @media ${device.desktopLG} {
    ${({ open }: { open: boolean }) =>
      open &&
      css`
        height: 285px;
      `}

    p {
      max-width: 190px;
    }
  }

  @media ${device.desktopXL} {
    ${({ open }: { open: boolean }) =>
      open &&
      css`
        height: 300px;
      `}

    p {
      max-width: 270px;
    }
  }

  ul {
    padding-left: 16px;
    margin-bottom: 0;
  }

  ul li {
    color: ${grayscale[400]};
    font-size: 14px;
    line-height: 17px;

    @media ${device.desktopXL} {
      font-size: 16px;
      line-height: 19px;
    }
  }
`

export const TransitionArrow = styled.div<{ open: boolean }>`
  display: flex;
  transition: all 0.3s ease;

  ${({ open }: { open: boolean }) =>
    open &&
    css`
      transform: rotate(-180deg);
    `}
`
